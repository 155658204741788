<template>
  <b-container class="container">
    <div class="tree">
      <b-button-group>
        <b-button
          class="mutable"
          variant="flat-primary"
          :disabled="atRoot"
          title="Go to the top-level (root) collection"
          @click="getRootCollection"
        >
          <feather-icon icon="FolderIcon" />
          Root
        </b-button>
        <b-button class="mutable" variant="flat-primary" title="Back" :disabled="navIndex === 0" @click="back">
          <feather-icon icon="ChevronLeftIcon" size="16" />
        </b-button>
        <b-button class="mutable" variant="flat-primary" title="Forward" :disabled="navIndex === navStack.length - 1" @click="forward">
          <feather-icon icon="ChevronRightIcon" size="16" />
        </b-button>
      </b-button-group>
      <!-- Search -->
      <b-button-group class="full-width">
        <b-input-group size="sm m-50 mb-25">
          <b-form-input
            id="filterInput"
            v-model="treeFilter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!treeFilter"
              @click="treeFilter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-button-group>
      <!-- ./Search -->
      <hr class="mt-0 mb-50">

      <div class="overflow-auto flex-grow-1 ml-50">
        <b-spinner v-if="!treeData" style="position: absolute; top: 50%; left: 50%;" />
        <CollectionsTree
          v-else
          :node="treeData"
          :focus="parentColl.id"
          begin-open
          @open="open"
        />
      </div>
      <div class="font-small-3 text-secondary text-center mb-50">
        Double-click to open
      </div>
    </div>

    <div class="table">
      <h3 class="pt-1 pl-1">
        {{ parentColl.name }}
      </h3>
      <b-button-group style="position: absolute; right: 1em; top: 1em;">
        <b-button
          variant="outline-info"
          size="sm"
          @click="getCollection"
        >
          <feather-icon
            icon="RefreshCwIcon"
          />
        </b-button>
        <b-button
          variant="outline-warning"
          size="sm"
          @click="updateCollection(parentColl)"
        >
          <feather-icon icon="FolderIcon" />
          Update
        </b-button>
        <b-button
          variant="outline-success"
          size="sm"
          @click="addCollection"
        >
          <feather-icon icon="FolderPlusIcon" />
          Create
        </b-button>
        <b-button
          variant="success"
          size="sm"
          @click="addItem"
        >
          <feather-icon icon="PlusIcon" />
          Add Items
        </b-button>
      </b-button-group>
      <p class="font-weight-bold mt-1 pl-1">
        {{ parentColl.description || 'No description' }}
      </p>
      <div class="d-flex justify-content-between flex-wrap pl-1 pr-1">

        <!-- Sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="1"
          label-for="sortBySelect"
          label-class="mt-50 mr-50 pl-0"
          class="ml-0 mb-0 text-nowrap"
          style="min-width: 20rem"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <!-- ./Sorting  -->

        <!-- Search -->
        <b-form-group
          label="Search"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          label-class="mt-50"
          class="pr-0 mb-0"
          style="width: 30rem"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!-- ./Search -->
      </div>
      <div class="h-100">
        <b-spinner v-if="!items" style="position: absolute; top: 50%; left: 50%;" />
        <div v-else-if="items.length === 0">
          <hr>
          <p class="font-weight-bold text-center">
            No items in collection.
          </p>
        </div>
        <b-table
          v-else
          sticky-header
          no-border-collapse
          class="mt-50 table-box"
          hover
          striped
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @row-dblclicked="open"
        >
          <template #cell(name)="entry">
            <feather-icon
              v-if="entry.item.label === 'Collection'"
              icon="FolderIcon"
              class="mb-25 text-primary icon"
            />
            <font-awesome-icon
              v-else-if="entry.item.label === 'Behaviour Tree'"
              :icon="['fas', 'vector-square']"
              class="text-info icon"
            />
            <font-awesome-icon
              v-else-if="entry.item.label === 'Specification'"
              :icon="['fas', 'clipboard-list']"
              class="icon"
              style="color: rgb(66, 86, 66)"
            />
            <font-awesome-icon
              v-else-if="entry.item.label === 'Interface'"
              :icon="['fas', 'bezier-curve']"
              class="icon"
              style="color: rgb(37, 68, 111)"
            />
            {{ entry.item.name }}
          </template>
          <template #cell(created)="entry">
            <span :title="entry.item.created">
              {{ entry.item.created | diffForHumansMaxTwoWeeks }}
            </span>
          </template>
          <template #cell(child_count)="entry">
            <div v-if="entry.item.label === 'Collection'" class="d-flex flex-row justify-content-center">
              <b-badge :variant="entry.item.child_count > 0 ? 'info' : 'muted'">
                {{ entry.item.child_count ? entry.item.child_count : '-' }}
              </b-badge>
            </div>
          </template>
          <template #cell(actions)="entry">
            <b-button-group size="sm">
              <b-button
                v-if="entry.item.label === 'Collection'"
                v-b-tooltip.hover title="Update Collection"
                variant="flat-warning"
                size="sm"
                @click="updateCollection(entry.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover title="Copy to another Collection"
                variant="flat-warning"
                size="sm"
                @click="move(entry.item)"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover :title="'Delete ' + entry.item.label"
                variant="flat-danger"
                size="sm"
                @click="remove(entry.item)"
              >
                <feather-icon icon="TrashIcon" class="mb-25" />
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </div>
    </div>

    <!-- Modals -->
    <CreateCollection @created="getCollection" />
    <UpdateCollection
      :collection="tempColl"
      :parent="parentColl.id"
      @updated="getCollection"
    />
    <DeleteCollection
      :collection="tempColl"
      :parent="parentColl.id"
      @deleted="getCollection"
    />
    <AddItem
      @added="getCollection"
    />
    <MigrateItem
      :collection="tempColl"
      :node="selectedNode"
      @migrated="getCollection"
    />
    <DeleteItem
      :collection="parentColl"
      :item="selectedNode"
      @deleted="getCollection"
    />
  </b-container>
</template>

<script>

import {
  computed, ref, set, watch,
} from '@vue/composition-api'
import store from '@/store'

import CreateCollection from '@/views/Collections/Modals/CreateCollection.vue'
import UpdateCollection from '@/views/Collections/Modals/UpdateCollection.vue'
import DeleteCollection from '@/views/Collections/Modals/DeleteCollection.vue'
import AddItem from '@/views/Collections/Modals/AddItem.vue'
import MigrateItem from '@/views/Collections/Modals/MigrateItem.vue'
import router from '@/router'
import CollectionsTree from '@/components/Collections/CollectionTree.vue'
import { BTable } from 'bootstrap-vue'
import DeleteItem from '@/views/Collections/Modals/DeleteItem.vue'

export default {
  name: 'Collections',
  components: {
    DeleteItem,
    BTable,
    CollectionsTree,
    MigrateItem,
    AddItem,
    // Modals
    CreateCollection,
    UpdateCollection,
    DeleteCollection,
  },
  setup(props, context) {
    const sortBy = ref('')
    const sortDesc = ref(false)
    const sortDirection = ref('asc')
    const sortOptions = computed(() => fields.value
      .filter(f => f.sortable)
      .map(f => ({ text: f.label, value: f.key })))

    const filter = ref(null)
    const filterOn = ref([])
    const treeFilter = ref('')
    watch(treeFilter, () => { search(treeData.value) })

    const fields = ref([
      {
        key: 'name',
        label: 'name',
        sortable: true,
        thStyle: { width: '40%' },
      },
      {
        key: 'label',
        label: 'type',
        sortable: true,
        thStyle: { width: '20%' },
      },
      {
        key: 'created',
        label: 'created',
        sortable: true,
        thStyle: { width: '30%' },
      },
      {
        key: 'child_count',
        label: 'items',
        sortable: true,
        thStyle: { width: '10%' },
      },
      {
        key: 'actions',
        sortable: false,
        class: 'text-center',
        thStyle: { width: '15%' },
      },
    ])
    const items = ref(null)
    const treeData = ref(null)
    const tempColl = ref({})
    const parentColl = ref({
      id: '',
      name: '',
    })
    const selectedNode = ref({})
    const navStack = ref([])
    const navIndex = ref(-1)
    const atRoot = ref(true)

    function getCollectionTree() {
      store.dispatch('collections/getCollectionTree', { items: true })
        .then(data => {
          treeData.value = data
          showChildren(treeData.value)
        })
    }

    function getRootCollection() {
      store.dispatch('collections/getRootCollection')
        .then(data => {
          store.state.collections.root = data.root
          atRoot.value = true
          store.state.collections.parent = data.root
          // trim stack down to new branch if different branch is active
          navStack.value.length = navIndex.value + 1
          navStack.value.push(data.root)
          navIndex.value += 1
          getCollection()
        })
    }

    function getCollection() {
      items.value = null
      const parentId = store.state.collections.parent.id
      store.dispatch('collections/getItems', { parent_id: parentId })
        .then(data => {
          getCollectionTree()
          atRoot.value = parentId === store.state.collections.root.id
          items.value = data.items
          parentColl.value = data.parent
        })
    }

    getRootCollection()

    function addCollection() {
      this.$bvModal.show('create-collection-modal')
    }

    function addItem() {
      this.$bvModal.show('add-item-modal')
    }

    function open(entry) {
      const label = entry.label || entry._type
      if (label === 'Collection' && entry.id !== parentColl.value.id) {
        store.state.collections.parent = entry
        // trim stack down to new branch if different branch is active
        navStack.value.length = navIndex.value + 1
        navStack.value.push(entry)
        navIndex.value += 1
        parentColl.value = entry
        getCollection()
      } else if (label === 'Specification') {
        store
          .dispatch('requirementsTableLegacy/setSpecification', entry.id)
          .then(() => {
            router.push({
              name: 'legacy_requirements_table',
              params: {
                modelId: sessionStorage.getItem('kompozition-workspace'),
                specId: entry.id,
              },
            })
          })
          .catch(e => console.error(e))
      } else if (['BehaviourTreeView', 'Behaviour Tree'].includes(label)) {
        store.commit('app/SET_DYNAMIC_PAGE_TITLE', entry.name)
        router.push({ name: 'joint_mbse_tree', params: { behaviourTreeId: entry.id } })
      } else if (label === 'Interface') {
        router.push({ name: 'interfaces' })
        store.dispatch('interfaces/selectInterface', entry.id)
      }
    }

    function updateCollection(coll) {
      tempColl.value = coll
      context.root.$bvModal.show('update-collection-modal')
    }

    function remove(entry) {
      if (entry.label === 'Collection') {
        tempColl.value = entry
        context.root.$bvModal.show('delete-collection-modal')
      } else {
        selectedNode.value = entry
        context.root.$bvModal.show('remove-item-modal')
      }
    }

    function move(entry) {
      selectedNode.value = entry
      context.root.$bvModal.show('migrate-item-modal')
    }

    function search(node) {
      // if node name matches filter, show itself and its children
      const name = (node.name || node.title).toLowerCase()
      if (name.includes(treeFilter.value.toLowerCase())) {
        showChildren(node)
        return true
      }
      // otherwise show node if one of its children matched the filter
      set(node, 'show', node.has_child && node.has_child.map(search).includes(true))
      return node.show
    }

    function showChildren(node) {
      set(node, 'show', true)
      if (node.has_child) {
        node.has_child.map(showChildren)
      }
    }

    function back() {
      navIndex.value -= 1
      const coll = navStack.value[navIndex.value]
      store.state.collections.parent = coll
      parentColl.value = coll
      getCollection()
    }

    function forward() {
      navIndex.value += 1
      const coll = navStack.value[navIndex.value]
      store.state.collections.parent = coll
      parentColl.value = coll
      getCollection()
    }

    return {
      sortBy,
      sortDesc,
      sortOptions,
      sortDirection,
      filter,
      filterOn,
      treeFilter,
      fields,
      items,
      treeData,
      tempColl,
      parentColl,
      selectedNode,
      navStack,
      navIndex,
      atRoot,

      getRootCollection,
      getCollection,
      addCollection,
      open,
      updateCollection,
      remove,
      addItem,
      move,
      search,
      back,
      forward,
    }
  },
}

</script>

<style scoped>

.container {
  display: grid;
  grid-template-columns: [left-wall] 1fr [split1] 15px [split2] 3fr [right-wall];
  grid-template-rows: [top] calc(100vh - 15rem) [bottom];
}

.tree {
  grid-column: left-wall / split1;
  white-space: nowrap;
  border-radius: 10px;
  background-color: rgb(40, 48, 70);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.table {
  grid-column: split2 / right-wall;
  height: 100%;
  position: relative;
  border-radius: 10px;
}

.table-box {
  overflow: hidden;
  scrollbar-gutter: stable both-edges;
  max-height: 85%;
}

.table-box:hover {
  overflow: auto;
}

.icon {
  height: 1em;
  width: 1em;
}

.mutable {
  border-radius: 10px 10px 0 0;
}

.mutable:disabled {
  color: rgb(99, 104, 114);
}

.mutable:enabled:hover {
  color: rgb(247, 153, 28);
  background-color: transparent;
  border-color: rgb(247, 153, 28);
}

.mutable:focus {
  color: rgb(247, 153, 28);
  background-color: transparent;
  border-color: transparent;
}
</style>
